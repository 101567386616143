"use strict";

import Storage from './Storage.js';
import Language from './Language.js';
import RequestFactory from './RequestFactory.js';

const Framework7 = window.Framework7;
const Template7 = window.Template7;
const $$ = window.Dom7;

const KEY_NAME = "name";
const KEY_QUERY = "query";
const KEY_ONINIT = "onInit";
const KEY_CALLBACKS = "callbacks";
const KEY_VIEW = "view";

const KEY_TEMPLATES_COMPILED = "compilation";
const KEY_TEMPLATES_CONTENT = "content";

const $ = jQuery;
const Request = new RequestFactory();

const PageFactory = function(f7){
    if(f7 instanceof Framework7){
        let _templates = {};

        class Page {
            constructor(name, view, { onInit = null }) {
                let callbacks = {}; 

                this.storage = new Storage();
                this.language = new Language();

                this.storage.set(KEY_NAME, name);
                this.storage.set(KEY_QUERY, {});
                this.storage.set(KEY_ONINIT, onInit);
                this.storage.set(KEY_VIEW, view);

                //Bindegem els events de la pàgina
                callbacks.onInit = {
                    event: "page:init",
                    callback: onInit
                };

                $$(`.page[data-name="${name}"]`).on(callbacks.onInit.event, (e, page) => {
                    page.context = this.storage.get(KEY_QUERY);

                    if (typeof callbacks.onInit.callback === "function")
                        callbacks.onInit.callback.apply(this, [page]);
                });

                this.storage.set(KEY_CALLBACKS, callbacks);
            }

            static getTemplate(name, returnCompiled = true){
                return new Promise((resolve, reject) => {
                    let key = name;

                    if (!_templates[key]){
                        Request
                            .getFile(`/templates/tpl${name.capitalize()}.html`)
                            .then((content) => { 
                                _templates[key] = { 
                                    [KEY_TEMPLATES_COMPILED]: Template7.compile(content),
                                    [KEY_TEMPLATES_CONTENT]: content
                                };

                                resolve(_templates[key][returnCompiled ? KEY_TEMPLATES_COMPILED : KEY_TEMPLATES_CONTENT]);
                            })
                            .catch(reject)
                        ;
                    }
                    else
                        resolve(_templates[key][returnCompiled ? KEY_TEMPLATES_COMPILED : KEY_TEMPLATES_CONTENT]);
                });
            }

            get name(){
                return this.storage.get(KEY_NAME);
            }

            get query(){
                return this.storage.get(KEY_QUERY);
            }

            get view(){
                return this.storage.get(KEY_VIEW);
            }

            destroy(){
                let callbacks = this.storage.get(KEY_CALLBACKS);
                for (let prop in callbacks) {
                    $$(`.page[data-name="${this.name}"]`).off(callbacks[prop].event);
                }

                this.storage.clear();
            }

            render({ query = {}, left = [], title = null, right = [], data = {}, view = null }){
                //Recollida de la plantilla base
                const compilePage = (contentCompiled) => {
                    return new Promise((resolve, reject) => {
                        this.constructor
                            .getTemplate("page")
                            .then(pageCompiled => resolve({ page: pageCompiled, content: contentCompiled }))
                            .catch(reject)
                        ;
                    });
                };

                //Compliació del html
                const mountPage = (compilations) => {
                    let _data = $.extend(data, { t: this.language.dictionary });
                    let _html = compilations.page(
                        {
                            left,
                            title,
                            right,
                            t: this.language.dictionary,
                            content: compilations.content(_data),
                            page: { name: this.name },
                            view: this.view
                        }
                    );

                    return _html;
                };

                //Set query
                this.storage.set(KEY_QUERY, query);

                //Render
                left.push({ class: "back", iconname: "left" });

                return new Promise((resolve, reject) => {
                    this.constructor
                        .getTemplate(this.name.replace("page", ""))
                        .then(compilePage)
                        .then(mountPage)
                        .then(resolve)
                        .catch(reject)
                    ;
                }); 
            }
        }

        return Page;
    }

    return false;
};

export default PageFactory;