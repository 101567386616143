"use strict";

import Storage from './Storage.js';
import ViewFactory from './ViewFactory.js';

const KEY_IS_INIT = "isInit";
const KEY_VIEW = "view";
const Framework7 = window.Framework7;
const $$ = window.Dom7;

class App{
    constructor(){
        this.f7 = new Framework7({
            root: 'body',
            theme: 'ios',
            panel: {
                swipe: 'right',
                rightBreakpoint: 1024,
            },
            init: false
        });
        this.views = {};
        this.storage = new Storage();

        //Opcions
        this.storage.set(KEY_IS_INIT, false);
        this.storage.set(KEY_VIEW, new ViewFactory(this.f7));

        //Eliminem el splash screen del DOM
        $$(".splash-screen").remove();
    }

    get isInit(){
        return this.storage.get(KEY_IS_INIT);
    }

    addView(name, selector, options){
        let View = this.storage.get(KEY_VIEW);
        this.views[name] = new View(name, selector, options || {});
        return this.views[name];
    }

    alert(msg, title){
        return new Promise((resolve, reject) => {
            if(!msg || !title){
                reject("Invalid messagr and/or title");
                return;
            }

            this.f7.dialog.alert(msg, title, resolve);
        });
    }

    init(){
        if (!this.isInit){
            this.f7.init();
            this.storage.set(KEY_IS_INIT, true);
        }
    }
}

export default App;