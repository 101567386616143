"use strict";

import Storage from './Storage.js';
import RequestFactory from './RequestFactory.js';
import Language from './Language.js';

const Template7 = window.Template7;
const $$ = window.Dom7;

const KEY_CONTAINER = "container";
const KEY_PAGE = "page";
const KEY_FILTERS = "filters";
const KEY_EVENTS = "events";
const KEY_TEMPLATE = "template";
const KEY_TRIGGERS = "triggers";
const KEY_ITEMSPERPAGE = "itemsPerPage";

const EVENT_PROCESSDATA = "processData";
const EVENT_REACHEND = "reachEnd";
const EVENT_RESET = "reset";

const Request = new RequestFactory();
const language = new Language();

let compiled = null;
let retrieved = false;
let isReachedEnd = false;
let isRetrieving = false;

class EventList{
    constructor({ $container, template, itemsPerPage = 10 }){
        this.storage = new Storage();
        this.storage.set(KEY_CONTAINER, $container);
        this.storage.set(KEY_FILTERS, {});
        this.storage.set(KEY_EVENTS, []);
        this.storage.set(KEY_TEMPLATE, template);
        this.storage.set(KEY_TRIGGERS, {});
        this.storage.set(KEY_ITEMSPERPAGE, itemsPerPage);

        compiled = Template7.compile(template);

        this.page = 1;

        $$(this.container).empty();
    }

    get container(){
        return this.storage.get(KEY_CONTAINER);
    }

    get page(){
        return this.storage.get(KEY_PAGE);
    }

    get itemsPerPage(){
        return this.storage.get(KEY_ITEMSPERPAGE);
    }

    set page(page){
        if(isNaN(parseInt(page, 10)))
            return this.page;
        
        if(page !== this.page)
            retrieved = false;

        if (isReachedEnd && page < this.page)
            isReachedEnd = false;

        this.storage.set(KEY_PAGE, parseInt(page, 10));

        return this.page;
    }

    next(){
        this.page = this.page + 1;
        
        return this.render();
    }

    prev(){
        this.page = (this.page > 1 ? this.page - 1 : 1);

        return this.render();
    }

    get filters(){
        return this.storage.get(KEY_FILTERS);
    }

    set filters(filters){
        if(typeof filters !== "object")
            return this.filters;

        retrieved = false;
        this.storage.set(KEY_FILTERS, filters);

        return this.filters;
    }

    get events(){
        return this.storage.get(KEY_EVENTS);
    }

    retrieve(){
        let me = this;

        if (isRetrieving) return;
        isRetrieving = true;

        return new Promise((resolve, reject) => {
            if(retrieved){
                isRetrieving = false;
                resolve(this.events);
                return;
            }

            let request = new Request();
            request.send(
                Request.PATHS.events,
                {
                    data: window.$.extend(me.filters, { pagina: me.page }),
                    error: (error) => {
                        reject(error);
                        retrieved = false;
                    },
                    success: (data) => {
                        me.storage.set(KEY_EVENTS, data);

                        resolve(data);
                        retrieved = true;
                    },
                    complete: () => { isRetrieving = false; }
                }
            );
        });
    }

    render(before = false){
        let me = this;

        if (isRetrieving) return;

        return new Promise((resolve, reject) => {
            if (isReachedEnd){
                reject("end of list");
                return;
            }

            this.retrieve()
                .then((data) => {
                    if (data && data.length < this.itemsPerPage){
                        if(this.onReachEnd)
                            this.onReachEnd();
                        
                        isReachedEnd = true;
                    }

                    let html = "";
                    data.forEach((v, i) => {
                        if (me.onProcessData)
                            v = this.onProcessData(v);

                        html += compiled($.extend(v, { t: language.dictionary })); 
                    });

                    if (before)
                        me.container.prepend(html);
                    else
                        me.container.append(html);

                    resolve(data);
                })
                .catch(reject)
            ; 
        });  
    }

    refresh(){
        retrieved = false;
        return this.render();
    }

    reset(){
        if (this.onReset && isReachedEnd)
            this.onReset();

        $$(this.container).empty();
        this.page = 1;

        isReachedEnd = false;

        return this.render();
    }

    set onProcessData(onProcessData){
        let triggers = this.storage.get(KEY_TRIGGERS);

        if(typeof onProcessData === "function")
            triggers[EVENT_PROCESSDATA] = onProcessData;

        this.storage.set(KEY_TRIGGERS, triggers);

        return triggers[EVENT_PROCESSDATA];
    }

    set onReachEnd(onReachEnd){
        let triggers = this.storage.get(KEY_TRIGGERS);

        if (typeof onReachEnd === "function")
            triggers[EVENT_REACHEND] = onReachEnd;

        this.storage.set(KEY_TRIGGERS, triggers);

        return triggers[EVENT_REACHEND];
    }

    set onReset(onReset){
        let triggers = this.storage.get(KEY_TRIGGERS);

        if (typeof onReset === "function")
            triggers[EVENT_RESET] = onReset;

        this.storage.set(KEY_TRIGGERS, triggers);

        return triggers[EVENT_RESET];
    }

    get onReset(){
        return this.storage.get(KEY_TRIGGERS)[EVENT_RESET]; 
    }

    get onReachEnd(){
        return this.storage.get(KEY_TRIGGERS)[EVENT_REACHEND];
    }

    get onProcessData(){
        return this.storage.get(KEY_TRIGGERS)[EVENT_PROCESSDATA];
    }
}

export default EventList;