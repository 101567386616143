"use strict";

let containers = new WeakMap();
let options = new WeakMap();

const KEY_IN_MEMORY = "inMemory";

class Storage{
    constructor(inMemory = true){
        options.set(this, {});
        options.get(this)[KEY_IN_MEMORY] = inMemory;

        if(inMemory)
            containers.set(this, {});
    }

    get inMemory(){
        return options.get(this)[KEY_IN_MEMORY];
    }

    get(key){
        if(this.inMemory)
            return containers.get(this)[key];
        else
            return window.localStorage && window.localStorage.getItem(key);
    }

    set(key, value){
        if(this.inMemory)
            containers.get(this)[key] = value;
        else if(window.localStorage)
            window.localStorage.setItem(key, value);
        else 
            return false;

        return value;
    }

    remove(key){
        if (this.inMemory)
            containers.get(this)[key] = null;
        else if (window.localStorage)
            window.localStorage.removeItem(key);
        else
            return false;
        
        return true;
    }

    clear(){
        if(this.inMemory)
            containers.delete(this);
        else if (window.localStorage)
            window.localStorage.clear();
        else
            return false;
        
        return true;
    }
}

export default Storage;