"use strict";

const $$ = window.Dom7;

String.prototype.capitalize = function(){
    return this.charAt(0).toUpperCase() + this.slice(1);
};

class DOMUtils {
    isElementInView(element, fullyInView, container) {
        if(!container)
            container = window;

        var pageTop = $$(container).scrollTop();
        var pageBottom = pageTop + $$(container).height();
        var elementTop = $$(element).offset().top;
        var elementBottom = elementTop + $$(element).height();

        //Barra de navegació
        elementTop -= 44; 
        pageTop -= 44;

        if (fullyInView === true) {
            return ((pageTop < elementTop) && (pageBottom > elementBottom));
        } else {
            return ((elementTop <= pageBottom) && (elementBottom >= pageTop));
        }
    }
}

class DateUtils {
    static parse(string, withUTC = true) {
        //TODO: Verificar que compleixi amb el format adequat
        let parts = string.split(/[^0-9]/);
        if (withUTC)
            return Date.UTC(parts[0], parts[1] - 1, parts[2], parts[3], parts[4], parts[5]);
        else
            return new Date(parts[0], parts[1] - 1, parts[2], parts[3], parts[4], parts[5]);
    }
}

let _DOMUtils = new DOMUtils();

export { _DOMUtils as DOMUtils, DateUtils};