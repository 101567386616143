"use strict";

import Storage from './Storage.js';
import Language from './Language.js';

const KEY_QUEUE = "queue";
const KEY_ACCESS_TOKEN = "accessToken";
const KEY_REFRESH_TOKEN = "refreshToken";
const KEY_CLIENT_ID = "clientId";
const KEY_SECRET_ID = "secretId";

const $ = jQuery;
const RequestFactory = () => {
    let queue = [];
    let permanentStorage = new Storage(true);
    let language = new Language();

    class Request {
        static get PATHS() {
            let version = "v1";
            let prefix = `/api`;

            return {
                events: `${prefix}/evento`
            };
        }

        static getFile(path) {
            return new Promise(function (resolve, reject) {
                $.get(path).done(resolve).fail(reject);
            });
        }

        static get queue() {
            return queue;
        }

        static addRequest(request) {
            queue.push(request);

            return queue;
        }

        static removeRequest(request) {
            let index = queue.indexOf(request);
            if (index >= 0)
                queue.splice(index, 1);

            return queue;
        }

        static getErrorRequest(error) {
            if (error && error.responseJSON && error.responseJSON.error_description) {
                return error.responseJSON.error_description;
            }
            else if (error && error.responseJSON && error.responseJSON.status && error.responseJSON.message) {
                return error.responseJSON.message;
            }
            else {
                return language.trans("errors.generic");
            }
        }

        static areTokensBeingUpdated() {
            let response = false;

            $.each(queue, function (idx, req) {
                if (req.refreshToken) {
                    response = true;
                    return false;
                }
            });

            return response;
        }

        send(url, options) {
            if (!options) options = {};

            let me = this;

            //Defaults
            let defaults = {
                method: "GET",
                async: true,
                timeout: 30000,
                cache: false,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded", //Contingut de la informació
                    "Accept": "application/json", //Acceptem contingut JSON
                    "Accept-Language": language.language
                }
            };

            //Not customizable
            let notCustom = {
                dataType: "json",
                url,
                beforeSend: function (request) {
                    if (options.data && options.data.grant_type === "refresh_token")
                        request.refreshToken = true;

                    me.constructor.addRequest(request);
                },
                complete: function (request) {
                    me.constructor.removeRequest(request);

                    if (typeof options.complete === "function")
                        options.complete(request);
                },
                error: options.error
            };

            //Merge objects
            $.extend(true, defaults, options, notCustom);

            //Eliminem els headers que estiguin en false o null
            for (var i in defaults.headers) {
                if (defaults.headers.hasOwnProperty(i)) {
                    if (!defaults.headers[i]) {
                        delete defaults.headers[i];
                    }
                }
            }

            return $.ajax(defaults);
        }

        sendProtected(url, options) {
            if (this.constructor.areTokensBeingUpdated())
                setTimeout(function () { this.sendProtected(url, options); }, 1000);
            else {
                //Not customizable
                let _options = {
                    headers: { Authorization: `Bearer ${permanentStorage.get(KEY_ACCESS_TOKEN)}` },
                    complete: (request) => {
                        if (
                            request.status !== 401 &&
                            request.status !== 403 &&
                            options &&
                            typeof options.complete === "function"
                        )
                            options.complete(request);
                    },
                    error: (request) => {
                        if (request.status === 403 || request.status === 401) { //Access token caducat
                            if (this.constructor.areTokensBeingUpdated())
                                setTimeout(() => this.sendProtected(url, options), 1000);
                            else {
                                this.updateTokens()
                                    .then(() => setTimeout(() => this.sendProtected(url, options), 300))
                                    .catch((result) => {
                                        if (result == "logout" && typeof options.logout == "function") 
                                            options.logout();
                                        if (typeof options.error === "function") 
                                            options.error(request);
                                        if (typeof options.complete === "function") 
                                            options.complete(request); 
                                    })
                                ;
                            }
                        }
                        else {
                            if (typeof options.error === "function") 
                                options.error(request);
                            if (typeof options.complete === "function") 
                                options.complete(request);
                        }
                    }
                };

                let final = $.extend(true, {}, options, _options);
                this.send(url, final);
            }
        }

        updateTokens() {
            return new Promise((resolve, reject) => {
                if (this.constructor.areTokensBeingUpdated()) {
                    reject("Is already refreshing token");
                    return;
                }

                let newOptions = {
                    data: {
                        "grant_type": "refresh_token",
                        "refresh_token": permanentStorage.get(KEY_REFRESH_TOKEN),
                        "client_id": permanentStorage.get(KEY_CLIENT_ID),
                        "client_secret": permanentStorage.get(KEY_SECRET_ID)
                    },
                    method: "POST",
                    timeout: 0,
                    error: (request, typeError) => {
                        if (request.status === 400) //Refresh token caducat
                            reject("logout");

                        reject(request);
                    },
                    success: (data) => {
                        if (data && data.access_token && data.refresh_token) {
                            //Si rebem les dades de nou, les registrem
                            permanentStorage.set(KEY_ACCESS_TOKEN, data && data.access_token);
                            permanentStorage.set(KEY_REFRESH_TOKEN, data && data.refresh_token);

                            resolve();
                        }
                        else {
                            reject("Invalid tokens");
                        }
                    }
                };

                this.send(this.constructor.PATHS.login, newOptions);
            });
        }
    }

    return Request;
};

export default RequestFactory;