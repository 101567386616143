"use strict";
import App from "./../Bundles/App.js";
import Language from "./../Bundles/Language.js";
import EventList from './../Bundles/EventList.js'; 
import RequestFactory from './../Bundles/RequestFactory.js';
import { DateUtils, DOMUtils } from './../Bundles/Utils.js';

const $$ = window.Dom7;
const Request = new RequestFactory();

//Google Maps
let isMapReady = false;

window.initMap = () => {
    isMapReady = true;
};

//Framework7
let app = new App();
let language = new Language();

let mainView = app.addView("view-main", ".view-main");
let rightView = app.addView("view-right", ".view-right");
let list = null;

const categoriaSelector = ".categoria.contenedor";
const disabledClass = "deshabilitado";
const disabledSelector = `.${disabledClass}`;
const inputsSelectors = {
    busqueda: "#search_filter",
    ubicacion: "#search_location",
    distancia: "#search_distance",
    precio: "#search_price",
    map: "google_map",
    ubicacionDependencias: ".show-on-location",
    participativo: "#search_participatory",
    limpiar: "#search_clear",
    submit: "#search_submit"
};

//Variables compartides entre les dues pàgines
const onInitIndexPage = async function(page){
    $$(window).trigger("resize"); //QUe es mostri el buscador

    let content = page.$el.find(".page-content").addClass("infinite-scroll-content");
    app.f7.infiniteScroll.create(content);

    $$(content).scroll((e) => {
        if (app && app.f7 && app.f7.navbar) {
            let isVisible = DOMUtils.isElementInView("#sumus-header", false, content);
            if (!isVisible)
                app.f7.navbar.show('.navbar');
            else
                app.f7.navbar.hide(".navbar");
        }
    }).trigger("scroll");

    const file = "/templates/partials/tplEvento.html";

    //AJAX i carregar els 10 primer events
    try{
        let template = await Request.getFile(file);
        let isLoading = false;

        const onInfinite = () => {
            if (isLoading) return;
            isLoading = true;

            let promise = list.next();
            if(promise){
                promise
                    .then((events) => { isLoading = false; })
                    .catch(() => { isLoading = false; })
                ;
            }
            else
                isLoading = false;
        };

        list = new EventList({ $container: page.$el.find(".row.content-left").eq(0), template, itemsPerPage: 10 });
        list.onReset = () => {
            app.f7.infiniteScroll.create(page.$el);
            page.$el.find(".preloader").show();
            page.$el.on("infinite", onInfinite);
        };
        list.onReachEnd = () => {
            app.f7.infiniteScroll.destroy(page.$el);
            page.$el.find(".preloader").hide();
        };
        list.onProcessData = (data) => {
            let Intl = null;

            if(window.Intl)
                Intl = window.Intl;
            else{
                Intl = require('intl');
                switch(language.language){
                    case "es":
                        require(`intl/locale-data/jsonp/es.js`);
                        break;
                    case "en":
                        require(`intl/locale-data/jsonp/en.js`);
                        break;
                    case "ca":
                        require(`intl/locale-data/jsonp/en.js`);
                        break;
                }
            }

            let optionsHour = {hour: '2-digit', minute:'2-digit'};

            //Fecha de publicación
            let publicationDate = DateUtils.parse(data.fechaPublicacion);
            let pDate = new Intl.DateTimeFormat().format(publicationDate);
            let pHour = new Intl.DateTimeFormat([], optionsHour).format(publicationDate);

            data.fechaPublicacion = `
                ${language.trans("dates.prefix_date.singular")} 
                ${pDate} 
                ${language.trans("dates.prefix_hour.singular")} 
                ${pHour}
            `;
            
            //Fecha de inicio i fecha final
            let startDate = DateUtils.parse(data.fechaInicio, false);
            let endDate = DateUtils.parse(data.fechaFinal, false);

            let sDate = new Intl.DateTimeFormat().format(startDate);
            let sHour = new Intl.DateTimeFormat([], optionsHour).format(startDate);

            let eDate = new Intl.DateTimeFormat().format(endDate);
            let eHour = new Intl.DateTimeFormat([], optionsHour).format(endDate);
            
            if(sDate == eDate){
                data.fecha = `
                    ${language.trans("dates.prefix_date.singular")}
                    ${sDate}
                `;
            }
            else{
                data.fecha = `
                    ${language.trans("dates.prefix_date.plural.first")}
                    ${sDate}
                    ${language.trans("dates.prefix_date.plural.middle")}
                    ${eDate}
                `;
            }

            //Horario
            if((sHour == "00:00" || sHour == "0:00") && eHour == "23:59")
                data.horario = language.trans("dates.all_day");
            else if (sHour == eHour || eHour == "23:59"){
                data.horario = `
                    ${language.trans("dates.prefix_hour.singular").capitalize()} 
                    ${sHour}
                `;
            }
            else{
                data.horario = `
                    ${language.trans("dates.prefix_hour.plural.first")} 
                    ${sHour} 
                    ${language.trans("dates.prefix_hour.plural.middle")} 
                    ${eHour}
                `;
            }

            //Habitual
            let twoWeeks = 1000 * 60 * 60 * 24 * 14;
            if (data.dias && data.dias.length && (endDate - startDate >= twoWeeks)){
                if (data.dias.length == 7) {
                    data.habil = language.trans("dates.all_week");
                }
                else if (data.dias.length == 1){
                    data.habil = `
                        ${language.trans("dates.prefix_habitual.plural")} 
                        ${language.dictionary.dates.days.plural[data.dias[0]]}        
                    `;
                }
                else{
                    let days = data.dias.map(v => language.dictionary.dates.days.plural[v]);
                    let last = days.splice(-1, 1);

                    data.habil = `
                        ${language.trans("dates.prefix_habitual.plural")} 
                        ${days.join(", ")} 
                        ${language.trans("dates.prefix_habitual.join")} 
                        ${last}
                    `;
                }
            }

            return data;
        };

        list.render();
        page.$el.on("infinite", onInfinite);
    }
    catch(e){
        throw `File ${file} not found`;
    }
}

let priceRange = null,
    distanceRange = null
;

const onShowSidePanel = function(page){
    if(!priceRange)
        priceRange = app.f7.range.create({
            el: inputsSelectors.precio,
            label: true,
            dual: false,
            min: 0,
            max: 3000,
            value: 3000
        });
};

const onInitSidePanel = function(page){
    let position = null;
    let timeoutLoadingMap = null;

    const hideLocation = () => {
        $$(inputsSelectors.ubicacionDependencias).hide();
        window.$(`${inputsSelectors.ubicacion} input`).prop("checked", false);
    };

    const onLocationError = () => {
        position = null;
        app.alert(language.trans("location.failed"), language.trans("general.error"));

        hideLocation();
    };

    const onLocationSuccess = (userPosition) => {
        position = {
            "latitud": userPosition.coords.latitude,
            "longitud": userPosition.coords.longitude
        };

        if (timeoutLoadingMap)
            window.clearTimeout(timeoutLoadingMap);

        timeoutLoadingMap = loadMap(userPosition);
    };

    const onMapReady = (position) =>{
        let defaultDistance = 10; //KMs

        if (!distanceRange)
            distanceRange = app.f7.range.create({
                el: inputsSelectors.distancia,
                label: true,
                dual: false,
                min: 0,
                max: 250,
                value: defaultDistance
            });

        let center = new window.google.maps.LatLng(position.coords.latitude, position.coords.longitude);
        let optionsmap = {
            zoom: 10,
            center: center,
            mapTypeControl: true,
            zoomControl: true,
            streetViewControl: false,
            rotateControl: true,
            panControl: true,
            scaleControl: true,
            overviewMapControl: true,
            draggable: false,
            mapTypeId: window.google.maps.MapTypeId.ROADMAP
        };

        let mapsearch = new window.google.maps.Map(document.getElementById(inputsSelectors.map), optionsmap);

        window.google.maps.event.addListenerOnce(mapsearch, 'idle', () => {
            let marker = new window.google.maps.Marker({
                position: center,
                map: mapsearch
            });

            let cityCircle = new window.google.maps.Circle({
                strokeColor: '#FF0000',
                strokeOpacity: 0.2,
                strokeWeight: 1,
                fillColor: '#FF0000',
                fillOpacity: 0.10,
                map: mapsearch,
                center: center,
                radius: defaultDistance * 1000 //Metres
            });

            distanceRange.on("change", function(range){
                cityCircle.setRadius(range.value * 1000);
                mapsearch.fitBounds(cityCircle.getBounds());
            });
        });
    };

    const locationOptions = {
        enableHighAccuracy: true,
        maximumAge: 30000,
        timeout: 27000
    };
    const loadMap = (position) => {
        if(isMapReady)
            onMapReady(position);
        else
            window.setTimeout(() => loadMap(position), 100);
    };
    
    const onLocationChange = (isChecked) => {
        if (isChecked) {
            $$(inputsSelectors.ubicacionDependencias).show();

            if ("geolocation" in navigator)
                navigator.geolocation.getCurrentPosition(onLocationSuccess, onLocationError, locationOptions);
            else
                onLocationError();
        }
        else{
            position = null;
            hideLocation();
        }
    };

    let isChecked = false;
    $$(inputsSelectors.ubicacion).on("change", () => {
        isChecked = !isChecked;
        onLocationChange(isChecked);
    });

    onLocationChange(isChecked);

    page.$el.find(categoriaSelector).on("click", function(){
        $$(this).toggleClass(disabledClass);
    });
    $$(inputsSelectors.limpiar).on("click", () => {
        //Netejem els filtres

        //Categories
        page.$el.find(`${categoriaSelector}`).removeClass(disabledClass);

        //Cerca
        $$(inputsSelectors.busqueda).val("").trigger("change");

        //Preu
        priceRange.setValue(priceRange.max);

        //Mapa
        position = null;
        hideLocation();

        //Participativo
        let input = $$(inputsSelectors.participativo).val(-1);
        input.parent().find(".item-after:not(:empty)").text(input.find("option[selected]").text());

        $$(inputsSelectors.submit).click();
    });
    $$(inputsSelectors.submit).on("click", () => {
        if (list instanceof EventList) {
            let participativo = $$(inputsSelectors.participativo).val();
            let categorias = [];
            page.$el.find(`${categoriaSelector}:not(${disabledSelector})`).each(function(){
                categorias.push($$(this).data("id"));
            });

            list.filters = {
                "categorias": categorias,
                "busqueda": $$(inputsSelectors.busqueda).val(),
                "precio": $$(inputsSelectors.precio).val()
            };

            if(participativo != -1)
                list.filters.participativo = participativo;

            if (position) {
                list.filters = $.extend(list.filters, {
                    'latitud': position.latitud,
                    'longitud': position.longitud,
                    'distancia': $$(`${inputsSelectors.distancia} input`).val()
                });
            }

            list.reset(); 
        }

        app.f7.panel.close("right");
    });
};

let indexPage = mainView.addPage("index", { onInit: onInitIndexPage } );
let sidePage = rightView.addPage("right-side", {  onInit: onInitSidePanel } );

app.f7.panel.get("right").on("opened", onShowSidePanel);

app.init();