"use strict";

import Storage from './Storage.js';
import PageFactory from './PageFactory.js';

const Framework7 = window.Framework7;

const KEY_NAME = "name";
const KEY_F7VIEW = "f7View";
const KEY_PAGES = "pages";

const ViewFactory = function(f7){
    if(f7 instanceof Framework7){
        let Page = new PageFactory(f7);

        class View {
            constructor(name, selector, options = {}) {
                let defaults = {
                    dynamicNavbar: true,
                    uniqueHistory: true,
                    domCache: true
                };
                
                window.$.extend(defaults, options);

                this.storage = new Storage();

                this.storage.set(KEY_PAGES, {});
                this.storage.set(KEY_NAME, name);
                this.storage.set(
                    KEY_F7VIEW, 
                    f7.views.create(selector, defaults)
                );
            }

            get pages(){
                return this.storage.get(KEY_PAGES);
            }

            addPage(name, { onInit = null }){
                this.pages[name] = new Page(name, this, { onInit });

                return this.pages[name];
            }

            removePage(name){
                if (!this.pages[name]) return false;

                this.pages[name].destroy();
                this.pages[name] = null;
            }

            loadPage(name, { query, left = [], title = null, right = [], data = {} }){
                if(!this.pages[name]) return false;

                let _html = this.pages[name].render({ query, left, title, right, data });
                this.storage.get(KEY_F7VIEW).router.loadContent(_html);

                return this.pages[name];
            }

            refreshActivePage(){
                this.storage.get(KEY_F7VIEW).router.refreshPage();
            }

            destroy(){
                for(let prop in this.pages){
                    this.pages[prop].destroy();
                }

                this.storage.clear();
            }
        }

        return View;
    }

    return false;
};

export default ViewFactory;