"use strict";

import Storage from './Storage.js';

const KEY_LANGUAGE = "language";
const KEY_FALLBACK = "fallback";
const VALUE_FALLBACK = "es";
const _dictionary = {
    es: {
        general: {
            error: "Error"
        },
        location: {
            failed: "No se ha podido recuperar la ubicación actual"
        },
        dates: {
            short_days: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
            prefix_date: {
                singular: 'El',
                plural: {
                    first: 'Desde el',
                    middle: 'hasta el'
                }
            },
            prefix_hour: {
                singular: 'a las',
                plural: {
                    first: 'Desde las',
                    middle: 'hasta las'
                }
            },
            prefix_habitual: {
                singular: 'Solo el',
                plural: 'Solo los',
                join: 'y'
            },
            all_day: "Todo el día",
            all_week: "Toda la semana",
            months: [
                'Enero',
                'Febrero',
                'Marzo',
                'Abril',
                'Mayo',
                'Junio',
                'Julio',
                'Agosto',
                'Septiembre',
                'Octubre',
                'Noviembre',
                'Diciembre'
            ],
            days: {
                singular: [
                    'Lunes',
                    'Martes',
                    'Miércoles',
                    'Jueves',
                    'Viernes',
                    'Sábado',
                    'Domingo'
                ],
                plural: [
                    'Lunes',
                    'Martes',
                    'Miércoles',
                    'Jueves',
                    'Viernes',
                    'Sábados',
                    'Domingos'
                ]
            }
        },
        events:{
            type: "Tipo de evento",
            expired: "Evento expirado",
            public: "Público",
            private: "Privado"
        }
    },

    en: {
        general: {
            error: "Error"
        },
        location: {
            failed: "The location could not be recovered"
        },
        dates: {
            short_days: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
            prefix_date: {
                singular: 'The',
                plural: {
                    first: 'From',
                    middle: 'until'
                }
            },
            prefix_hour: { 
                singular: 'at',
                plural: {
                    first: 'From',
                    middle: 'until'
                }
            },
            prefix_habitual: {
                singular: 'Only',
                plural: 'Only',
                join: "and"
            },
            all_day: "All the day",
            all_week: "All the week",
            months: [
                'January',
                'February',
                'March',
                'April',
                'May',
                'June',
                'July',
                'August',
                'September',
                'October',
                'November',
                'December'
            ],
            days: {
                singular: [
                    'Monday',
                    'Tuesday',
                    'Wednesday',
                    'Thursday',
                    'Friday',
                    'Saturday',
                    'Sunday'
                ],
                plural: [
                    'Mondays',
                    'Tuesdays',
                    'Wednesdays',
                    'Thursdays',
                    'Fridays',
                    'Saturdays',
                    'Sundays'
                ]
            }
        },
        events: {
            type: "Type of event",
            expired: "Expired",
            public: "Public",
            private: "Private"
        }
    },

    ca: {
        general: {
            error: "Error"
        },
        location: {
            failed: "No s'ha pogut recuperar la ubicació"
        },
        dates: {
            short_days: ['Dg', 'Dl', 'Dt', 'Dc', 'Dj', 'Dv', 'Ds'],
            prefix_date: {
                singular: 'El',
                plural: {
                    first: 'Des del',
                    middle: 'fins el'
                }
            },
            prefix_hour: {
                singular: 'a les',
                plural: {
                    first: 'Des de les',
                    middle: 'fins les'
                }
            },
            prefix_habitual: {
                singular: 'Només el',
                plural: 'Només els',
                join: 'i'
            },
            all_day: "Tot el día",
            all_week: "Tota la setmana",
            months: [
                'Gener',
                'Febrer',
                'Març',
                'Abril',
                'Maig',
                'Juny',
                'Juliol',
                'Agost',
                'Setembre',
                'Octubre',
                'Novembre',
                'Desembre'
            ],
            days: {
                singular: [
                    'Dilluns',
                    'Dimarts',
                    'Dimecres',
                    'Dijous',
                    'Divendres',
                    'Dissabte',
                    'Diumenge'
                ],
                plural: [
                    'Dilluns',
                    'Dimarts',
                    'Dimecres',
                    'Dijous',
                    'Divendres',
                    'Dissabtes',
                    'Diumenges'
                ]
            }
        },
        events: {
            type: "Tipus d'event",
            expired: "Event expirat",
            public: "Públic",
            private: "Privat"
        }
    }
};

const getUrlLanguage = () => { 
    let parts = window.location.pathname.split("/");
    return parts.length >= 2 ? parts[1] : null;
};
const getBrowserLanguage = () => {
    return navigator.language || navigator.userLanguage;
};
const getValueFromDictionary = (key, dictionary) => {
    if (!dictionary) return null;

    let props = key.toString().split(".");
    let result = dictionary;

    for (let item of props) {
        if (result && result[item])
            result = result[item];
        else
            return null;
    }

    return result;
};

class Language{
    constructor(){
        this.permanentStorage = new Storage(false);
        this.storage = new Storage();

        let browser = getBrowserLanguage();
        let url = getUrlLanguage();

        if(url)
            this.permanentStorage.set(KEY_LANGUAGE, url);
        else if(_dictionary[browser] && !this.permanentStorage.get(KEY_LANGUAGE))
            this.permanentStorage.set(KEY_LANGUAGE, browser);
        else if (!this.permanentStorage.get(KEY_LANGUAGE))
            this.permanentStorage.set(KEY_LANGUAGE, VALUE_FALLBACK);
        
        this.storage.set(KEY_FALLBACK, VALUE_FALLBACK);
    }

    get language(){
        return this.permanentStorage.get(KEY_LANGUAGE) || this.fallback;
    }

    set language(locale){
        if (_dictionary[locale])
            this.permanentStorage.set(KEY_LANGUAGE, locale);

        return this.language;
    }

    get dictionary(){
        if (_dictionary[this.language])
            return _dictionary[this.language];
        else
            return _dictionary[this.fallback];
    }

    get fallback(){
        return this.storage.get(KEY_FALLBACK);
    }

    trans(key) {
        let result = getValueFromDictionary(key, this.dictionary);

        if (!result) {
            result = getValueFromDictionary(key, _dictionary[this.fallback]);

            if (!result)
                return key.toString();
        }

        return result;
    }
}

export default Language;